.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* You can change to 'flex-start' for better cross-browser compatibility */
    margin: 20px 0;
    margin-bottom: 30px !important;
}

.img-bottom {
    padding-bottom: 25px;
}

/* Example of a media query for responsiveness */
@media screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column; /* Change the flex direction to column for smaller screens */
        align-items: center; /* Center align items for smaller screens */
    }
}