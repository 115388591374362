.date-element {
    margin-top: -15px;
    position: relative;
    width: 100%;
}

.date-element p {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    width: 45%;
    left: calc(50% - 114px/2 - 139px);
    top: 92.41%;
    bottom: -7.25%;
    background: #FAA819;
    border-radius: 2px;
}

.tours-section-carousel {
    padding-bottom: 80px;
}