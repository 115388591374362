
  .dropdown-toggle-custom {
    display: flex;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(235, 82, 35, 0.8) -5.8%);
    color: rgb(255, 255, 255);
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  }
  .filter-button{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }