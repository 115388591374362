.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.card-title {
    font-size: 30px;
    line-height: 30px;
}

.card-text {
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
}
.book-link {
    color: black;
    text-decoration: none;
}
.book-link:hover {
    color: black;
    text-decoration: none !important;
}
