.book-description{
    color: #494849;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    flex: none;
    order: 0;
    flex-grow: 1;
    text-transform: lowercase;
}

.book-description:first-letter{
    text-transform: capitalize;
}
.book-detailes{
    padding-left: 80px;
}
.book-detailes h1{
    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
}
.book-buttion{
    padding: 0px !important;
}
.share{
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 3.5px;
    text-transform: uppercase;    
    color: #1D1D1D;
    padding-top: 10px;
}
.book-social-media{
    padding-top: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.book-social-media a{
    margin-left: 1px;
    margin-right: 16px;
    gap: 10px;
    width: 32px;
    height: 32px;     
    background: rgba(29, 29, 29, 0.1);
    border: 1px solid rgba(29, 29, 29, 0.1);
    border-radius: 2px;      
    flex: none;
    order: 0;
    flex-grow: 0;
}
.book-social-media-icon{
    margin: 7px;
    color: #000;
}