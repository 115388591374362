.tours-link {
    text-decoration: none;
}

.tours-link:hover {
    text-decoration: none !important;
}

.tours-card {
    border: none !important;
    height: auto;
    max-width: 100%;
    width: 100%;
    /* Make sure the card is responsive with a maximum width */
}

.tours-card-content {
    padding-left: 16px;
    /* Simplify padding with shorthand */
}

.tours-card h4 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 150%;
    color: #1D1D1D;
}

.tours-card h3 {
    font-size: 2.5rem;
    line-height: 1.5;
    color: #EB5223;
}

.tours-card p {
    color: #8E8E8E;
}

.img-linear-gradient {
    width: 100%;
    /* Ensure the image takes up the full width */
    height: auto;
    /* Allow the height to adjust based on the width */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.149) 58.31%, rgba(255, 255, 255, 0.149) 58.31%, #FFFFFF 100.01%);
}

.image-container {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 200px;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    /* Make sure the image takes up the full width of the container */
    height: 100%;
    object-fit: cover;
}

.image-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.149) 58.31%, rgba(255, 255, 255, 0.149) 58.31%, #FFFFFF 100.01%);
}

.card-title {
    font-size: 1.375rem;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.card-details {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}