.tours-element {
    background: #F0F0F0;
    border-radius: 2px;
    display: flex;
    align-items: center; /* Vertically center align items */
    justify-content: center; /* Horizontally center align items */
    margin-bottom: 10px; /* Add space between rows */
    padding: 10px 25px; /* Add horizontal padding */
    box-sizing: border-box; 
    width: 100%;
}

.tours-element-img {
    flex-shrink: 0; /* Prevent the icon from shrinking */
    height: auto; /* Maintain aspect ratio */
}

.tours-element-p {
    margin: 0; /* Remove default margin */
    flex-grow: 1; /* Allow the text to take up the remaining space */
    white-space: nowrap; /* Prevent text from wrapping (optional) */
    text-overflow: ellipsis; /* Add ellipsis to overflow text */
    text-align: center; /* Center the text */
    font-size: small;
}


@media (max-width: 576px) {
    .tours-element {
        flex-direction: column; /* Stack icon and text vertically on small screens */
        align-items: center; /* Center align items */
        text-align: center; /* Center text */
        padding: 0 15px; /* Maintain horizontal padding */
        width: 100%;
    }

    .tours-element-img {
        margin-bottom: 5px; /* Add space below the icon */
        margin-right: 0; /* Remove right margin */
        margin-top: 5px;

    }

    .tours-element-p {
        white-space: normal; /* Allow text to wrap */
        text-align: center; /* Center text */
        max-width: 100%; /* Ensure text does not overflow the container */
    }
}