.gallery{
    padding-bottom: 80px;
}

.gallery-title-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;

}
.title-gallery h1{
    font-weight: 500;
    font-size: 70px;
    line-height: 55px;
}

.trip-gallery{
    width: 300px;
    border: 0;
}

.left-image-gallery{
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the image covers the entire area without distortion */
}
.trip-gallery{
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire area without distortion */
}

.d-flex {
    display: flex;
}

.flex-fill {
    flex: 1 1 auto;
}
.gallery-title-section,
.trip-gallery,
.left-image-gallery {
    margin: 0;
    padding: 0;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
/* Media query for mobile devices */
@media (max-width: 768px) {
    .title-section h1 {
        font-size: 30px;
        line-height: 35px;
    }
}
