.our-last-catalog-section{
    margin-top: 40px;

}
.our-last-catalog-section-img{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}
.our-last-catalog-content{
    box-sizing: border-box;
    align-items: flex-start;
    margin-left: 12px;
    margin-right: -12px;
    top: 1323px;
    border: 1px solid rgba(29, 29, 29, 0.1);
    border-radius: 2px;
    padding: 12px;
    
}
.form-header h1{
    font-weight: 500;
    font-size: 40px;
    line-height: 150%;
    /* identical to box height, or 60px */
    display: flex;
    align-items: center;
    text-transform: uppercase;   
    color: #131313;   
    /* Inside auto layout */   
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.form-header h2{
    font-weight: 500;
    font-size: 40px;
    line-height: 150%;
    /* identical to box height, or 60px */
    display: flex;
    align-items: center;
    text-transform: uppercase;
    /* Secondary/Secondary */
    color: #FAA819;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 582%;
    height: 100% !important;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}