

.tour-card {
    padding-top: 15px;
    /* Set width accordingly; for example, 23% to account for margins */
    width: 25%;
    margin-bottom: 15px;
    /* Adjust margin as needed */
}
/* Media query for smaller screens */
@media (max-width: 767px) {
    .tour-card {
        width: 50%;
        /* Two columns on smaller screens */
    }
}