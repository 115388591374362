table {
    border-collapse: collapse;
    width: '100%';
    margin: 0px;
}
th, td {
    border: 1px solid black;
    padding: 8px;
}
th {
    background-color: #f2f2f2;
}
button {
    margin-right: 5px;
    padding: 6px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px; 
}

.action-buttons button:hover {
    background-color: #45a049;
}
.action-buttons button.delete {
    background-color: #f44336;
}
.action-buttons button.delete:hover {
    background-color: #d32f2f;
}

.add-tour{
    display: flex;
    justify-content: center;
}
.form-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ccc;
    width: 80%;
}
.add-button{
    background-color: #008CBA;      
}

.item-list {
    list-style-type: none;
    padding-left: 0;
}

.item-list li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.item-list li button {
    margin-left: 10px;
    margin-top: 10px;
}
.truncate-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines to display */
    -webkit-box-orient: vertical;
}
    
/* Media query for screens smaller than 600px */
@media (max-width: 600px) {
    .form-container {
    width: 90%;
    }
}
.checkbox-container {
    display: flex;
    align-items: center;
}
.delete-btn {
    background-color: #f44336;
}