.footer {
    background-color: #1D1D1D;
    color: #FFFFFF;
    padding: 60px;
    margin-top: auto;
}

.footerTitle {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
}

.icon {
    margin: 5px;
}

.orange {
    color: #EB5223
}

.copyrightSection {
    background-color: #131313;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #8E8E8E;
}

.border-box-icon {
    box-sizing: border-box;
    padding: 4px;
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    display: inline-flex;
    margin-right: 16px;
    margin-bottom: 16px;
}

.icon {
    margin: 0 auto;
    padding-bottom: 0px;
}

a:link {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.newsletter-input input {
    width: 336px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 2px;
}


@media screen and (max-width: 954px) {
    .footerTitle{
        font-size: 29px;
    }
}

.text-reset{
    font-size: 14px !important;
}