.bigTitle {
    text-align: center;
    font-weight: 500;
    font-size: 80px;
    line-height: 80px;
    text-transform: uppercase;
    color: #1D1D1D;
}

.styles-module_slide__1zrfk{
    margin-top: 5%;
}