.numbers {
    font-weight: 500;
    font-size: 80px;
    line-height: 80px;
    color: #EB5223;

}
.centerBlock{
    display: table;
    margin: auto;
}