.padding-about-us{
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 15px;
}
iframe {
    width: 100%;
    padding: 0;
    border: 0;
    height: 100%;
}
.sub-title-about-us{
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}
.title-header h1{
    font-weight: 500;
    font-size: 78px;
    line-height: 60px;
    color: #1D1D1D;
}
.title-header h3{
    padding-left: 20px;
}
.title-header p{
    text-transform: none;
    text-align: justify;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 30px;
    color: #494849;
}
.images-chisiamo {
    width: 100%;
        padding: 0;
        border: 0;
        height: 100%;
}