.header-image-bg {
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    background-size: cover;
    height: 100vh;
    /* Adjust the height to be 100% of the viewport height */
    justify-content: center;
}
.header-image-sm-bg{
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    background-size: cover;
    height: 45vh;
    justify-content: center;
}

.about-us-title {
    font-weight: 500;
    font-size: 5rem;
    /* Adjust the font size using a relative unit */
    line-height: 1;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 6%;
}

.links-about-us {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 1rem;
    /* Adjust the font size using a relative unit */
    line-height: 1.25;
}

.description-text {
    width: 90%;
    /* Adjust the width to be a percentage of the parent container */
    max-width: 700px;
    /* Add a max-width to ensure the text doesn't become too wide on larger screens */
    color: #FFFFFF;
    font-size: 1rem;
    /* Adjust the font size using a relative unit */
    line-height: 1.875;
}

.hero-image-btn {
    padding: 1rem;
    /* Adjust the padding using a relative unit */
    height: auto;
    /* Allow the height to adjust according to the content */
    width: 100%;
    /* Adjust the width to be 100% of the parent container */
    border: 0;
    background: linear-gradient(315deg, #EB5223 0%, #FAA819 100%);
    font-size: 1rem;
    /* Adjust the font size using a relative unit */
    color: #FFFFFF;
    margin-top: 2rem;
    /* Adjust the margin using a relative unit */
}

.hero-image-outline-btn {
    background: transparent !important;
    color: #EB5223;
    border-radius: 2px;
    border: solid;
}

.icon {
    padding-left: 0;
    /* Remove units for zero padding */
}