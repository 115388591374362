.comma {
    font-size: 40px;
    line-height: 24px;
    color: #E81938;
}

.message {
    font-family: Teko;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

.sub-title {
    font-size: 20px;
    font-weight: 500;
}

.alltext {
    font-family: 'Noto Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding: 20px !important;
}

.row {
    padding: 10px;
}

.container {
    padding: 0;
}

.cover {
    width: 100%;
    height: 100%;
}