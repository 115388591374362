.NavbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 80px;
    font-size: 1.2rem;
    position: relative;
}

.navbar.scrolled {
    background-color: white;

}

.navbar-logo {
    height: 85px;
    text-transform: uppercase;
}

.menu-icon {
    display: none;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    list-style: none;
    text-align: center;
    padding-right: 11px !important;
    margin: 0px;
}

.nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
}

.menu-icon {
    display: none;
}

.nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    padding: 0 1rem;
    height: 100%;
    transition: border-bottom 0.5s ease-in-out;
}


@media screen and (max-width: 960px) {
    .media {
        margin-left: 88%;
    }

    .locationIcon {
        display: none;
    }

    .nav-menu {
        position: fixed;
        /* top: 80px; */
        left: -100%;
        width: 60%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        padding-right: 11px !important;
        margin: 0px;
    }

    .nav-menu.active {
        background-color: #fff;
        left: 0;
        top: 0;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .menu-icon {
        display: block;
        position: absolute;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #000;
        margin-top: -10%;
    }

    .nav-item {
        height: 60px;
        width: 100%;
    }

    .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        margin-top: 3%;
    }

    .social-links-navbar {
        display: none;
    }

    .navbar {
        margin: 0px !important;
        height: 10% !important;
    }
}

.upper-nav-links {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    height: 21px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.nav-bar-bg {
    height: 150px;
}

.nav-bar-bg-white {
    /* height:150px; */
    background-color: #FBF6F0;
    margin-top: 40px;
    height: 70px;
}

.btn-link {
    text-decoration: none !important;
}

.contact {
    margin-top: 3%;
    width: 87px;
    height: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(315deg, #EB5223 0%, #FAA819 100%);
    border-radius: 2px;
    flex: none;
    order: 1;
    color: #FFFFFF !important;
    margin-right: 0px !important;
    margin-left: 32px !important;
}

#links {

    text-decoration: none;
    font-family: 'Teko', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    display: flex;
    align-items: center;
    text-transform: uppercase;
    /* Alpha */

    color: #1D1D1D;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-right: 0px !important;
    padding-left: 0px !important;
    transition: border-bottom 0.5s ease-in-out;
}

#links>p {
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin: 0px;
    text-decoration: none !important;
}

#links:hover {
    color: #EB5223;
}



.social-links-navbar {
    color: #FFFFFF;
    height: 40px;
    width: 100% !important;
}

.icon {
    padding-right: 16px;
}

.locationIcon {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.btn-primary {
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn-primary :focus {
    background-color: transparent !important;
    border-color: transparent;
}

a{
    text-decoration: none !important;
}