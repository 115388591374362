.pros{
    flex-direction: column;
    align-items: flex-start;
    margin-right: 24px;
    margin-left: 24px;
    gap: 8px;
    padding: 24px;
    width:90%;
    background: rgba(163, 161, 170, 0.1);
     margin-bottom: 2%
}
.prps-row img{
    width: 2.5%;
}
.prps-row p {margin-top: 1.5%; margin-left: 1.5%;}
.prps-row img,
.prps-row p {
    display: inline-block;
    vertical-align: middle;
}

.logistica-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media screen and (max-width: 954px) {
    .logistica-section {
        flex-direction: column;
        align-items: center;
    }}

    .wrapper-trip-images {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem; 
      }
      
      .image-col-trip-images {
        padding: 0.5rem; 
      }
      
      .image-container-trip-images {
        width: 100%; 
        padding-top: 100%; 
        position: relative; 
      }
      
      .trip-images-trip-images {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        border-radius: 8px; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
      }
      