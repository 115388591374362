
.tours-section-carousel{
    padding-top: 25px;
}
.tours-section-grid {
    margin-top: 20px;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  