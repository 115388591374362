
.chi-siamo-title p{
    display: flex;
    text-align: justify;
    padding-top: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px; 
    color: #494849;
}
iframe {
    width: 100%;
    padding: 0;
    border: 0;
    height: 100%;
    padding-bottom: 40px !important ;
}