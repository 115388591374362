.gallery-image {
    height: 300px;
    width: 400px;
    margin: 10px;
}
.content{
    margin: 30% auto;
}
.card-title- {
    font-size: 40px !important;
    color: #FFFFFF !important;
    margin: 0 auto;
}

.subtitle {
    font-size: 24px !important;
    color: #FAA819 !important;
    margin: 0 auto;
}