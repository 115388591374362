/* The hero image */
.hero-image {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/video_hero_image.gif");
    /* Set a specific height */
    height: 721px;
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

/* Place text in the middle of the image */
.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}

.hero-image h1 {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 90px;
    line-height: 150%;

}

.center-button-contact {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

 
}

@media screen and (max-width: 954px) {
    .hero-image h1 {
        font-size: 30px;
    }
}