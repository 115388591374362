.button{
    padding-bottom: 3px;
    background: linear-gradient(315deg, #EB5223 0%, #FAA819 100%);
    border-radius: 2px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    text-transform: uppercase; 
    color: #FFFFFF;
}
.button-section{
    flex-direction: column;
    align-items: flex-start;
    padding-top: 12px;
}
.icon-button{
    padding-left: 2px;
    color: white;
    width: 32px;
    margin-bottom: 4px;
    margin-left: 4px;
}

