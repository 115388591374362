.hero-video {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.hero-video .video-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-video .mute-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: rgba(183, 180, 180, 0.8);
    padding: 10px;
    cursor: pointer;
    border: none;
}