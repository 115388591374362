
.container-our-team:hover{
    background-position: 100% 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(235, 82, 35, 0.8) 79.2%) ;

}
.div-our-team  {
   display: grid;
   justify-items: start;
   align-items: start;
   margin-top: 166px;
   margin-right: 55%;
   margin-left: 10%;
}
.our-team-name {
   position: relative;
    z-index: 10;
   font-weight: 500;
   font-size: 30px;
   line-height: 150%;
   display: flex;
   align-items: center;
   color: #FFFFFF;

}
.social-media-team hr{
   z-index: 10;
   height: 15px;
   color: #FFFFFF;
   width: 288%;
}
.social-media-team{
   display:block;
   z-index: 10;
   padding-bottom: 75px;
}
.hide {
    display: none;
}

.image-our-team {
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    z-index: 1;
    width: 100%;
    height: 35vh;
    opacity: 0.25;
}

.selector {
   filter: grayscale(100%);
   -webkit-filter: grayscale(100%);
}