.tour-date h3 {
    font-size: 40px;
    line-height: 57px;
    color: #EB5223 !important;

}
.scroll-timeline-nav ul li.active span:after,
.scroll-timeline-nav ul li:hover span:after {
    width: 150px;
        background-color: #e81938;
}
.tour-sub-title h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 43px;
    /* Legend/Legend 100 */
    color: #494849;
}

.hotel-description p {
    font-weight: 500;
    font-size: 20px;
    line-height: 10px;
    /* or 188% */
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    color: #000 !important
}

.hotel-image {
    width: 100%;
    height: 90%;
    display: block;
    margin-bottom: 5%;
}
.hotel-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.elementor-widget-container {
    margin: 0;
    padding: 60px 0;
}
.sidebar {
    position: relative;

}
.scroll-timeline-nav {
    width: 150px;
    padding: 0 10px;
    text-align: center;
    position: absolute;
    z-index: 99;
    top: 0 !important;
    overflow: hidden;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}
.scroll-timeline-nav ul {
    list-style: none;
    list-style-position: inside;
    margin: 0;
}
.scroll-timeline-nav ul li.active,
.scroll-timeline-nav ul li:hover {
    color: #EB5223;
}
.scroll-timeline-nav ul span {
    position: relative;
}
.scroll-timeline-nav ul li {
    color: #c6c6c6;
    margin-bottom: 40px;
    padding-left: 0;
    list-style-type: none;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    color: black;
    font-size: 18px;
    font-size: 1.125rem;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.scrollable-container {
    overflow: hidden;

}
.elementor-widget-scroll-timeline .st-section {
    padding-right: 80px;
}
.scroll-timeline-wrapper {
    margin: 0 auto;
    padding-left: 150px;
    width: 100%;
    margin-top: -15px;

}
.main-content {
    margin-left: 200px;
    /* Width of the sidebar */
    padding: 20px;
}
.sidebar-link a{
    text-decoration: none;
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    color: black ;
    padding-bottom: 5px;
    padding-left: 16px;
    cursor: pointer;
}

@media screen and (max-width: 954px) {
    .hotel-section {
        flex-direction: column;
        align-items: center;
    }
    .scroll-timeline-wrapper {
        margin: 0 auto;
        padding-left: 95px;
        width: 100%;
        margin-top: -15px;
    }
}
