input{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    height: 53px;
    background: #F0F0F0;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;    
    display: flex;
    align-items: center;
    color: #A3A1AA;

}
select {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    height: 53px;
    background: #F0F0F0;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #A3A1AA;

}

textarea{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    height: 100px;
    background: #F0F0F0;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;    
    display: flex;
    align-items: center;
    color: #A3A1AA;

}