.locationIcon {
    height: 30px;
    padding-left: 0px !important;

}

.title {
    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
    color: #1D1D1D !important;
}

.text-description {
    height: 443px;
    overflow: scroll;
    text-transform: none !important;
}

.next-team-section {
    padding-top: 20px;
    padding-left: 0px !important;
}

.next-team-section img {
    height: 100px;
    text-align: center;
    margin: 0 auto;
}

.section-title {
    padding-left: 0px !important;

}

.next-team-member-name {
    align-items: center;
    text-transform: uppercase;
    color: #1D1D1D;
    font-size: 30px;
}

.stuffSection {
    box-sizing: border-box;
    margin: 0 -12px;
    align-items: flex-start;
    border: 1px solid rgba(29, 29, 29, 0.1);
    border-radius: 2px;
    padding: 16px;
    margin: 80px auto;
}

.stuffSection img {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.bigImg {
    width: inherit;
}
.destination-list {
    list-style-type: disc; /* Use filled point circles */
    padding-left: 20px;
}
@media screen and (max-width: 954px) {
    .stuffSection .bigImg {
        width: 345px;
    }
}