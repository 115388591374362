.upcoming-event {
   background: #1D1D1D;
   padding-left: 20px !important;
   align-items: center;
   min-height: 260px !important;
}

.upcoming-event-description {
   display: flex;
   flex-direction: column;
   justify-content: justify;
   align-items: flex-start;
}

.title {
   font-weight: 500;
   font-size: 45px;
   color: #FFFFFF !important;
   flex: none;
   order: 0;
   flex-grow: 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   margin-top: 26px;
}

.event-name {
   text-decoration: none;
   font-weight: 500;
   font-size: 20px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   text-transform: uppercase;
   color: #EB5223;

}

.upcoming-event-img {
   padding: 0px !important;
   margin: 0px !important;
}

.upcoming-event-img img {
   height: 100% !important;
   width: 100% !important;
}

.join-us {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   padding-left: 40px !important;
}

.see-more {
   font-weight: 500;
   font-size: 24px;
   line-height: 18px;
   /* or 100% */
   text-decoration: none;
   display: flex;
   align-items: center;
   color: #EB5223;
   flex: none;
   order: 1;
   flex-grow: 0;
}

.desc-title {
   font-weight: 500;
   font-size: 45px;
   line-height: 40px;
   display: flex;
   align-items: center;
   text-align: start;
   padding-top: 50px;
   color: #1D1D1D;
   /* Inside auto layout */
   flex: none;
   order: 0;
   flex-grow: 0;
}

.countdown {
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   gap: 12px;
   height: 85px;
   flex: none;
   order: 1;
   align-self: stretch;
   flex-grow: 0;
}