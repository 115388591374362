
body {
    margin: 0;
    overflow-x: hidden; /* Hides the horizontal scroll bar */
}
.top {
    padding-top: 90px !important;
}

.top-padding {
    padding-top: 90px;
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.text-teko {
    font-family: 'Teko';
    font-style: normal;
    text-transform: uppercase;
}

.takoFont {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 3.5px;
}


.nanoSantaFont {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px !important;
    line-height: 30px;
    /* text-transform: capitalize; */
}

body {
    width: 100%;
}

.whatsappBtn {
    border-color: transparent;
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color:transparent;
    text-align: center;
    border-radius: 90px;
    height: 60px;
    width: 60px;
}

.whatsappBtn img {
    width: 70px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.hero-video {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.hero-video .video-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-video .mute-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: rgba(183, 180, 180, 0.8);
    padding: 10px;
    cursor: pointer;
    border: none;
}
.expired-notice {
    text-align: center;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
}

.expired-notice>span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
}

.expired-notice>p {
    font-size: 1.5rem;
}


.show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    flex-wrap: wrap;
}

.show-counter .countdown {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 10px 24px;
    background: #2A2A2A;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 10px 10px;
    width: 80px;
}

.show-counter .countdown.danger {
    color: #ff0000;
}

.show-counter .countdown>p {
    margin: 0;
}

.show-counter .countdown>span {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8E8E8E;
    flex: none;
    order: 1;
    flex-grow: 0;

}
.upcoming-event {
   background: #1D1D1D;
   padding-left: 20px !important;
   align-items: center;
   min-height: 260px !important;
}

.upcoming-event-description {
   display: flex;
   flex-direction: column;
   justify-content: justify;
   align-items: flex-start;
}

.title {
   font-weight: 500;
   font-size: 45px;
   color: #FFFFFF !important;
   flex: none;
   order: 0;
   flex-grow: 0;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   margin-top: 26px;
}

.event-name {
   text-decoration: none;
   font-weight: 500;
   font-size: 20px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   text-transform: uppercase;
   color: #EB5223;

}

.upcoming-event-img {
   padding: 0px !important;
   margin: 0px !important;
}

.upcoming-event-img img {
   height: 100% !important;
   width: 100% !important;
}

.join-us {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   padding-left: 40px !important;
}

.see-more {
   font-weight: 500;
   font-size: 24px;
   line-height: 18px;
   /* or 100% */
   text-decoration: none;
   display: flex;
   align-items: center;
   color: #EB5223;
   flex: none;
   order: 1;
   flex-grow: 0;
}

.desc-title {
   font-weight: 500;
   font-size: 45px;
   line-height: 40px;
   display: flex;
   align-items: center;
   text-align: start;
   padding-top: 50px;
   color: #1D1D1D;
   /* Inside auto layout */
   flex: none;
   order: 0;
   flex-grow: 0;
}

.countdown {
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   grid-gap: 12px;
   gap: 12px;
   height: 85px;
   flex: none;
   order: 1;
   align-self: stretch;
   flex-grow: 0;
}
.our-last-catalog-section{
    padding-top: 80px;
}
.our-last-catalog-section-img{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 10px;
    gap: 10px;
    width: 105%;
    height: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.our-last-catalog-content{
    box-sizing: border-box;
    align-items: flex-start;
    margin-left: 12px;
    margin-right: -12px;
    top: 1323px;
    border: 1px solid rgba(29, 29, 29, 0.1);
    border-radius: 2px;
    padding: 12px;
}
.form-header h1{
    font-weight: 500;
    font-size: 40px;
    line-height: 150%;
    /* identical to box height, or 60px */
    display: flex;
    align-items: center;
    text-transform: uppercase;   
    color: #131313;   
    /* Inside auto layout */   
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.form-header h2{
    font-weight: 500;
    font-size: 40px;
    line-height: 150%;
    /* identical to box height, or 60px */
    display: flex;
    align-items: center;
    text-transform: uppercase;
    /* Secondary/Secondary */
    color: #FAA819;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 16px;
    gap: 16px;
    width: 582%;
    height: 100% !important;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
input{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    grid-gap: 8px;
    gap: 8px;
    height: 53px;
    background: #F0F0F0;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;    
    display: flex;
    align-items: center;
    color: #A3A1AA;

}
select {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    grid-gap: 8px;
    gap: 8px;
    height: 53px;
    background: #F0F0F0;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #A3A1AA;

}

textarea{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    grid-gap: 8px;
    gap: 8px;
    height: 100px;
    background: #F0F0F0;
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;    
    display: flex;
    align-items: center;
    color: #A3A1AA;

}
.button{
    padding-bottom: 3px;
    background: linear-gradient(315deg, #EB5223 0%, #FAA819 100%);
    border-radius: 2px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    text-transform: uppercase; 
    color: #FFFFFF;
}
.button-section{
    flex-direction: column;
    align-items: flex-start;
    padding-top: 12px;
}
.icon-button{
    padding-left: 2px;
    color: white;
    width: 32px;
    margin-bottom: 4px;
    margin-left: 4px;
}


.date-element {
    margin-top: -15px;
    position: relative;
    width: 100%;
}

.date-element p {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    width: 45%;
    left: calc(50% - 114px/2 - 139px);
    top: 92.41%;
    bottom: -7.25%;
    background: #FAA819;
    border-radius: 2px;
}

.tours-section-carousel {
    padding-bottom: 80px;
}
.tours-element {
    background: #F0F0F0;
    border-radius: 2px;
    display: flex;
    align-items: center; /* Vertically center align items */
    justify-content: center; /* Horizontally center align items */
    margin-bottom: 10px; /* Add space between rows */
    padding: 10px 25px; /* Add horizontal padding */
    box-sizing: border-box; 
    width: 100%;
}

.tours-element-img {
    flex-shrink: 0; /* Prevent the icon from shrinking */
    height: auto; /* Maintain aspect ratio */
}

.tours-element-p {
    margin: 0; /* Remove default margin */
    flex-grow: 1; /* Allow the text to take up the remaining space */
    white-space: nowrap; /* Prevent text from wrapping (optional) */
    text-overflow: ellipsis; /* Add ellipsis to overflow text */
    text-align: center; /* Center the text */
    font-size: small;
}


@media (max-width: 576px) {
    .tours-element {
        flex-direction: column; /* Stack icon and text vertically on small screens */
        align-items: center; /* Center align items */
        text-align: center; /* Center text */
        padding: 0 15px; /* Maintain horizontal padding */
        width: 100%;
    }

    .tours-element-img {
        margin-bottom: 5px; /* Add space below the icon */
        margin-right: 0; /* Remove right margin */
        margin-top: 5px;

    }

    .tours-element-p {
        white-space: normal; /* Allow text to wrap */
        text-align: center; /* Center text */
        max-width: 100%; /* Ensure text does not overflow the container */
    }
}
.tours-link {
    text-decoration: none;
}

.tours-link:hover {
    text-decoration: none !important;
}

.tours-card {
    border: none !important;
    height: auto;
    max-width: 100%;
    width: 100%;
    /* Make sure the card is responsive with a maximum width */
}

.tours-card-content {
    padding-left: 16px;
    /* Simplify padding with shorthand */
}

.tours-card h4 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 150%;
    color: #1D1D1D;
}

.tours-card h3 {
    font-size: 2.5rem;
    line-height: 1.5;
    color: #EB5223;
}

.tours-card p {
    color: #8E8E8E;
}

.img-linear-gradient {
    width: 100%;
    /* Ensure the image takes up the full width */
    height: auto;
    /* Allow the height to adjust based on the width */
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.149) 58.31%, rgba(255, 255, 255, 0.149) 58.31%, #FFFFFF 100.01%);
}

.image-container {
    position: relative;
    width: 100%;
    /* Ensure the container takes up the full width */
    height: 200px;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    /* Make sure the image takes up the full width of the container */
    height: 100%;
    object-fit: cover;
}

.image-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.149) 58.31%, rgba(255, 255, 255, 0.149) 58.31%, #FFFFFF 100.01%);
}

.card-title {
    font-size: 1.375rem;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.card-details {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.tours-section-carousel{
    padding-top: 25px;
}
.tours-section-grid {
    margin-top: 20px;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  
.subTitle {
    text-align: center;
    font-family: 'Teko';
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 3.6px;
    text-transform: uppercase;
}
.bigTitle {
    text-align: center;
    font-weight: 500;
    font-size: 80px;
    line-height: 80px;
    text-transform: uppercase;
    color: #1D1D1D;
}

.styles-module_slide__1zrfk{
    margin-top: 5%;
}
.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.card-title {
    font-size: 30px;
    line-height: 30px;
}

.card-text {
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
}
.book-link {
    color: black;
    text-decoration: none;
}
.book-link:hover {
    color: black;
    text-decoration: none !important;
}

.title-section h1{
    font-weight: 500;
    font-size: 80px;
    line-height: 80px;
}
.numbers {
    font-weight: 500;
    font-size: 80px;
    line-height: 80px;
    color: #EB5223;

}
.centerBlock{
    display: table;
    margin: auto;
}



.chi-siamo-title p{
    display: flex;
    text-align: justify;
    padding-top: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px; 
    color: #494849;
}
iframe {
    width: 100%;
    padding: 0;
    border: 0;
    height: 100%;
    padding-bottom: 40px !important ;
}
.gallery{
    padding-bottom: 80px;
}

.gallery-title-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;

}
.title-gallery h1{
    font-weight: 500;
    font-size: 70px;
    line-height: 55px;
}

.trip-gallery{
    width: 300px;
    border: 0;
}

.left-image-gallery{
    width: 100%;
    height: 100%;
    object-fit: cover; /* This ensures the image covers the entire area without distortion */
}
.trip-gallery{
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire area without distortion */
}

.d-flex {
    display: flex;
}

.flex-fill {
    flex: 1 1 auto;
}
.gallery-title-section,
.trip-gallery,
.left-image-gallery {
    margin: 0;
    padding: 0;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
/* Media query for mobile devices */
@media (max-width: 768px) {
    .title-section h1 {
        font-size: 30px;
        line-height: 35px;
    }
}


.our-team-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0% !important;
    padding-bottom: 20px;
    grid-gap: 30px;
    gap: 30px;
}
 .container-our-team {
     background: #000;
     background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 79.2%);
     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
     background-repeat: no-repeat;
     display: flex;
     height: 35vh;
     max-width: 100vw;
     max-height: 100vh;
     justify-content: center;
     align-items: center;
     position: relative;
     cursor: pointer;
     transition: 0.5s all;
     color: white;
 }



.container-our-team:hover{
    background-position: 100% 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(235, 82, 35, 0.8) 79.2%) ;

}
.div-our-team  {
   display: grid;
   justify-items: start;
   align-items: start;
   margin-top: 166px;
   margin-right: 55%;
   margin-left: 10%;
}
.our-team-name {
   position: relative;
    z-index: 10;
   font-weight: 500;
   font-size: 30px;
   line-height: 150%;
   display: flex;
   align-items: center;
   color: #FFFFFF;

}
.social-media-team hr{
   z-index: 10;
   height: 15px;
   color: #FFFFFF;
   width: 288%;
}
.social-media-team{
   display:block;
   z-index: 10;
   padding-bottom: 75px;
}
.hide {
    display: none;
}

.image-our-team {
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    z-index: 1;
    width: 100%;
    height: 35vh;
    opacity: 0.25;
}

.selector {
   filter: grayscale(100%);
   -webkit-filter: grayscale(100%);
}
/* The hero image */
.hero-image {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/video_hero_image.b6679843.gif);
    /* Set a specific height */
    height: 721px;
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

/* Place text in the middle of the image */
.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}

.hero-image h1 {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 90px;
    line-height: 150%;

}

.center-button-contact {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

 
}

@media screen and (max-width: 954px) {
    .hero-image h1 {
        font-size: 30px;
    }
}
.NavbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 80px;
    font-size: 1.2rem;
    position: relative;
}

.navbar.scrolled {
    background-color: white;

}

.navbar-logo {
    height: 85px;
    text-transform: uppercase;
}

.menu-icon {
    display: none;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    list-style: none;
    text-align: center;
    padding-right: 11px !important;
    margin: 0px;
}

.nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
}

.menu-icon {
    display: none;
}

.nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-weight: bold;
    padding: 0 1rem;
    height: 100%;
    transition: border-bottom 0.5s ease-in-out;
}


@media screen and (max-width: 960px) {
    .media {
        margin-left: 88%;
    }

    .locationIcon {
        display: none;
    }

    .nav-menu {
        position: fixed;
        /* top: 80px; */
        left: -100%;
        width: 60%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        padding-right: 11px !important;
        margin: 0px;
    }

    .nav-menu.active {
        background-color: #fff;
        left: 0;
        top: 0;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .menu-icon {
        display: block;
        position: absolute;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #000;
        margin-top: -10%;
    }

    .nav-item {
        height: 60px;
        width: 100%;
    }

    .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        margin-top: 3%;
    }

    .social-links-navbar {
        display: none;
    }

    .navbar {
        margin: 0px !important;
        height: 10% !important;
    }
}

.upper-nav-links {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    height: 21px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.nav-bar-bg {
    height: 150px;
}

.nav-bar-bg-white {
    /* height:150px; */
    background-color: #FBF6F0;
    margin-top: 40px;
    height: 70px;
}

.btn-link {
    text-decoration: none !important;
}

.contact {
    margin-top: 3%;
    width: 87px;
    height: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(315deg, #EB5223 0%, #FAA819 100%);
    border-radius: 2px;
    flex: none;
    order: 1;
    color: #FFFFFF !important;
    margin-right: 0px !important;
    margin-left: 32px !important;
}

#links {

    text-decoration: none;
    font-family: 'Teko', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */

    display: flex;
    align-items: center;
    text-transform: uppercase;
    /* Alpha */

    color: #1D1D1D;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-right: 0px !important;
    padding-left: 0px !important;
    transition: border-bottom 0.5s ease-in-out;
}

#links>p {
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin: 0px;
    text-decoration: none !important;
}

#links:hover {
    color: #EB5223;
}



.social-links-navbar {
    color: #FFFFFF;
    height: 40px;
    width: 100% !important;
}

.icon {
    padding-right: 16px;
}

.locationIcon {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.btn-primary {
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn-primary :focus {
    background-color: transparent !important;
    border-color: transparent;
}

a{
    text-decoration: none !important;
}
.padding-about-us{
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 15px;
}
iframe {
    width: 100%;
    padding: 0;
    border: 0;
    height: 100%;
}
.sub-title-about-us{
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 16px;
    gap: 16px;
}
.title-header h1{
    font-weight: 500;
    font-size: 78px;
    line-height: 60px;
    color: #1D1D1D;
}
.title-header h3{
    padding-left: 20px;
}
.title-header p{
    text-transform: none;
    text-align: justify;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 30px;
    color: #494849;
}
.images-chisiamo {
    width: 100%;
        padding: 0;
        border: 0;
        height: 100%;
}
.header-image-bg {
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    background-size: cover;
    height: 100vh;
    /* Adjust the height to be 100% of the viewport height */
    justify-content: center;
}
.header-image-sm-bg{
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    background-size: cover;
    height: 45vh;
    justify-content: center;
}

.about-us-title {
    font-weight: 500;
    font-size: 5rem;
    /* Adjust the font size using a relative unit */
    line-height: 1;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 6%;
}

.links-about-us {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 1rem;
    /* Adjust the font size using a relative unit */
    line-height: 1.25;
}

.description-text {
    width: 90%;
    /* Adjust the width to be a percentage of the parent container */
    max-width: 700px;
    /* Add a max-width to ensure the text doesn't become too wide on larger screens */
    color: #FFFFFF;
    font-size: 1rem;
    /* Adjust the font size using a relative unit */
    line-height: 1.875;
}

.hero-image-btn {
    padding: 1rem;
    /* Adjust the padding using a relative unit */
    height: auto;
    /* Allow the height to adjust according to the content */
    width: 100%;
    /* Adjust the width to be 100% of the parent container */
    border: 0;
    background: linear-gradient(315deg, #EB5223 0%, #FAA819 100%);
    font-size: 1rem;
    /* Adjust the font size using a relative unit */
    color: #FFFFFF;
    margin-top: 2rem;
    /* Adjust the margin using a relative unit */
}

.hero-image-outline-btn {
    background: transparent !important;
    color: #EB5223;
    border-radius: 2px;
    border: solid;
}

.icon {
    padding-left: 0;
    /* Remove units for zero padding */
}
.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* You can change to 'flex-start' for better cross-browser compatibility */
    margin: 20px 0;
    margin-bottom: 30px !important;
}

.img-bottom {
    padding-bottom: 25px;
}

/* Example of a media query for responsiveness */
@media screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column; /* Change the flex direction to column for smaller screens */
        align-items: center; /* Center align items for smaller screens */
    }
}
.locationIcon {
    height: 30px;
    padding-left: 0px !important;

}

.title {
    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
    color: #1D1D1D !important;
}

.text-description {
    height: 443px;
    overflow: scroll;
    text-transform: none !important;
}

.next-team-section {
    padding-top: 20px;
    padding-left: 0px !important;
}

.next-team-section img {
    height: 100px;
    text-align: center;
    margin: 0 auto;
}

.section-title {
    padding-left: 0px !important;

}

.next-team-member-name {
    align-items: center;
    text-transform: uppercase;
    color: #1D1D1D;
    font-size: 30px;
}

.stuffSection {
    box-sizing: border-box;
    margin: 0 -12px;
    align-items: flex-start;
    border: 1px solid rgba(29, 29, 29, 0.1);
    border-radius: 2px;
    padding: 16px;
    margin: 80px auto;
}

.stuffSection img {
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.bigImg {
    width: inherit;
}
.destination-list {
    list-style-type: disc; /* Use filled point circles */
    padding-left: 20px;
}
@media screen and (max-width: 954px) {
    .stuffSection .bigImg {
        width: 345px;
    }
}
.about-us-nembers{
    display: flex;
    align-items: center;
    background: url(/static/media/about_us_numbers.8ba3c611.png) no-repeat;
    background-size: cover;
    height: 419px !important;
    width: 100%;
}



.tour-card {
    padding-top: 15px;
    /* Set width accordingly; for example, 23% to account for margins */
    width: 25%;
    margin-bottom: 15px;
    /* Adjust margin as needed */
}
/* Media query for smaller screens */
@media (max-width: 767px) {
    .tour-card {
        width: 50%;
        /* Two columns on smaller screens */
    }
}
.footer {
    background-color: #1D1D1D;
    color: #FFFFFF;
    padding: 60px;
    margin-top: auto;
}

.footerTitle {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
}

.icon {
    margin: 5px;
}

.orange {
    color: #EB5223
}

.copyrightSection {
    background-color: #131313;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #8E8E8E;
}

.border-box-icon {
    box-sizing: border-box;
    padding: 4px;
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    display: inline-flex;
    margin-right: 16px;
    margin-bottom: 16px;
}

.icon {
    margin: 0 auto;
    padding-bottom: 0px;
}

a:link {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.newsletter-input input {
    width: 336px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 2px;
}


@media screen and (max-width: 954px) {
    .footerTitle{
        font-size: 29px;
    }
}

.text-reset{
    font-size: 14px !important;
}
.comma {
    font-size: 40px;
    line-height: 24px;
    color: #E81938;
}

.message {
    font-family: Teko;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

.sub-title {
    font-size: 20px;
    font-weight: 500;
}

.alltext {
    font-family: 'Noto Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding: 20px !important;
}

.row {
    padding: 10px;
}

.container {
    padding: 0;
}

.cover {
    width: 100%;
    height: 100%;
}
.testimonials-title {
    font-size: 16px;
    font-weight: 500;
}

.message {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    text-transform:none;
}

.comma{
font-size: 60px;
line-height: 24px;
color: #E81938;
}

  .dropdown-toggle-custom {
    display: flex;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(235, 82, 35, 0.8) -5.8%);
    color: rgb(255, 255, 255);
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  }
  .filter-button{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
.our-last-catalog-section{
    margin-top: 40px;

}
.our-last-catalog-section-img{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
    height: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}
.our-last-catalog-content{
    box-sizing: border-box;
    align-items: flex-start;
    margin-left: 12px;
    margin-right: -12px;
    top: 1323px;
    border: 1px solid rgba(29, 29, 29, 0.1);
    border-radius: 2px;
    padding: 12px;
    
}
.form-header h1{
    font-weight: 500;
    font-size: 40px;
    line-height: 150%;
    /* identical to box height, or 60px */
    display: flex;
    align-items: center;
    text-transform: uppercase;   
    color: #131313;   
    /* Inside auto layout */   
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.form-header h2{
    font-weight: 500;
    font-size: 40px;
    line-height: 150%;
    /* identical to box height, or 60px */
    display: flex;
    align-items: center;
    text-transform: uppercase;
    /* Secondary/Secondary */
    color: #FAA819;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    grid-gap: 16px;
    gap: 16px;
    width: 582%;
    height: 100% !important;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.book-description{
    color: #494849;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    flex: none;
    order: 0;
    flex-grow: 1;
    text-transform: lowercase;
}

.book-description:first-letter{
    text-transform: capitalize;
}
.book-detailes{
    padding-left: 80px;
}
.book-detailes h1{
    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
}
.book-buttion{
    padding: 0px !important;
}
.share{
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 3.5px;
    text-transform: uppercase;    
    color: #1D1D1D;
    padding-top: 10px;
}
.book-social-media{
    padding-top: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.book-social-media a{
    margin-left: 1px;
    margin-right: 16px;
    grid-gap: 10px;
    gap: 10px;
    width: 32px;
    height: 32px;     
    background: rgba(29, 29, 29, 0.1);
    border: 1px solid rgba(29, 29, 29, 0.1);
    border-radius: 2px;      
    flex: none;
    order: 0;
    flex-grow: 0;
}
.book-social-media-icon{
    margin: 7px;
    color: #000;
}
.gallery-image {
    height: 300px;
    width: 400px;
    margin: 10px;
}
.content{
    margin: 30% auto;
}
.card-title- {
    font-size: 40px !important;
    color: #FFFFFF !important;
    margin: 0 auto;
}

.subtitle {
    font-size: 24px !important;
    color: #FAA819 !important;
    margin: 0 auto;
}
.pros{
    flex-direction: column;
    align-items: flex-start;
    margin-right: 24px;
    margin-left: 24px;
    grid-gap: 8px;
    gap: 8px;
    padding: 24px;
    width:90%;
    background: rgba(163, 161, 170, 0.1);
     margin-bottom: 2%
}
.prps-row img{
    width: 2.5%;
}
.prps-row p {margin-top: 1.5%; margin-left: 1.5%;}
.prps-row img,
.prps-row p {
    display: inline-block;
    vertical-align: middle;
}

.logistica-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media screen and (max-width: 954px) {
    .logistica-section {
        flex-direction: column;
        align-items: center;
    }}

    .wrapper-trip-images {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 1rem;
        gap: 1rem; 
      }
      
      .image-col-trip-images {
        padding: 0.5rem; 
      }
      
      .image-container-trip-images {
        width: 100%; 
        padding-top: 100%; 
        position: relative; 
      }
      
      .trip-images-trip-images {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        border-radius: 8px; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
      }
      
.tour-date h3 {
    font-size: 40px;
    line-height: 57px;
    color: #EB5223 !important;

}
.scroll-timeline-nav ul li.active span:after,
.scroll-timeline-nav ul li:hover span:after {
    width: 150px;
        background-color: #e81938;
}
.tour-sub-title h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 43px;
    /* Legend/Legend 100 */
    color: #494849;
}

.hotel-description p {
    font-weight: 500;
    font-size: 20px;
    line-height: 10px;
    /* or 188% */
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    color: #000 !important
}

.hotel-image {
    width: 100%;
    height: 90%;
    display: block;
    margin-bottom: 5%;
}
.hotel-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.elementor-widget-container {
    margin: 0;
    padding: 60px 0;
}
.sidebar {
    position: relative;

}
.scroll-timeline-nav {
    width: 150px;
    padding: 0 10px;
    text-align: center;
    position: absolute;
    z-index: 99;
    top: 0 !important;
    overflow: hidden;
    transition: all .3s ease-out;
}
.scroll-timeline-nav ul {
    list-style: none;
    list-style-position: inside;
    margin: 0;
}
.scroll-timeline-nav ul li.active,
.scroll-timeline-nav ul li:hover {
    color: #EB5223;
}
.scroll-timeline-nav ul span {
    position: relative;
}
.scroll-timeline-nav ul li {
    color: #c6c6c6;
    margin-bottom: 40px;
    padding-left: 0;
    list-style-type: none;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    color: black;
    font-size: 18px;
    font-size: 1.125rem;
    transition: all .3s ease;
}
.scrollable-container {
    overflow: hidden;

}
.elementor-widget-scroll-timeline .st-section {
    padding-right: 80px;
}
.scroll-timeline-wrapper {
    margin: 0 auto;
    padding-left: 150px;
    width: 100%;
    margin-top: -15px;

}
.main-content {
    margin-left: 200px;
    /* Width of the sidebar */
    padding: 20px;
}
.sidebar-link a{
    text-decoration: none;
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    color: black ;
    padding-bottom: 5px;
    padding-left: 16px;
    cursor: pointer;
}

@media screen and (max-width: 954px) {
    .hotel-section {
        flex-direction: column;
        align-items: center;
    }
    .scroll-timeline-wrapper {
        margin: 0 auto;
        padding-left: 95px;
        width: 100%;
        margin-top: -15px;
    }
}

table {
    border-collapse: collapse;
    width: '100%';
    margin: 0px;
}
th, td {
    border: 1px solid black;
    padding: 8px;
}
th {
    background-color: #f2f2f2;
}
button {
    margin-right: 5px;
    padding: 6px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px; 
}

.action-buttons button:hover {
    background-color: #45a049;
}
.action-buttons button.delete {
    background-color: #f44336;
}
.action-buttons button.delete:hover {
    background-color: #d32f2f;
}

.add-tour{
    display: flex;
    justify-content: center;
}
.form-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ccc;
    width: 80%;
}
.add-button{
    background-color: #008CBA;      
}

.item-list {
    list-style-type: none;
    padding-left: 0;
}

.item-list li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.item-list li button {
    margin-left: 10px;
    margin-top: 10px;
}
.truncate-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines to display */
    -webkit-box-orient: vertical;
}
    
/* Media query for screens smaller than 600px */
@media (max-width: 600px) {
    .form-container {
    width: 90%;
    }
}
.checkbox-container {
    display: flex;
    align-items: center;
}
.delete-btn {
    background-color: #f44336;
}
