.expired-notice {
    text-align: center;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
}

.expired-notice>span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
}

.expired-notice>p {
    font-size: 1.5rem;
}


.show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    flex-wrap: wrap;
}

.show-counter .countdown {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 10px 24px;
    background: #2A2A2A;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 10px 10px;
    width: 80px;
}

.show-counter .countdown.danger {
    color: #ff0000;
}

.show-counter .countdown>p {
    margin: 0;
}

.show-counter .countdown>span {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8E8E8E;
    flex: none;
    order: 1;
    flex-grow: 0;

}