.testimonials-title {
    font-size: 16px;
    font-weight: 500;
}

.message {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    text-transform:none;
}

.comma{
font-size: 60px;
line-height: 24px;
color: #E81938;
}