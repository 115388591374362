body {
    margin: 0;
    overflow-x: hidden; /* Hides the horizontal scroll bar */
}
.top {
    padding-top: 90px !important;
}

.top-padding {
    padding-top: 90px;
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.text-teko {
    font-family: 'Teko';
    font-style: normal;
    text-transform: uppercase;
}

.takoFont {
    font-family: 'Teko';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 3.5px;
}


.nanoSantaFont {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px !important;
    line-height: 30px;
    /* text-transform: capitalize; */
}

body {
    width: 100%;
}

.whatsappBtn {
    border-color: transparent;
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color:transparent;
    text-align: center;
    border-radius: 90px;
    height: 60px;
    width: 60px;
}

.whatsappBtn img {
    width: 70px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}
