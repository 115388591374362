
.our-team-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0% !important;
    padding-bottom: 20px;
    gap: 30px;
}
 .container-our-team {
     background: #000;
     background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 79.2%);
     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
     background-repeat: no-repeat;
     display: flex;
     height: 35vh;
     max-width: 100vw;
     max-height: 100vh;
     justify-content: center;
     align-items: center;
     position: relative;
     cursor: pointer;
     transition: 0.5s all;
     color: white;
 }

